@use '../base' as inf;

.mat-mdc-tab-header  {
  border-bottom: 1px solid inf.$infrest-standard-border-color !important;
}

.mat-mdc-tab-group {
  height: 100%;
}

.mat-mdc-tab-body-wrapper {
  height: 100%;
}

.mat-mdc-tab:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label,
.mat-mdc-tab-label-active {
  color: inf.$infrest-primary-variant-700 !important;
}
