
@mixin root-definitions() {

  --white: oklch(100% 0 0);
  --bg-gray: oklch(98.51% 0 0); // #fafafa
  --bg-gray-accent: oklch(62.09% 0.026 250.22); // #7b8896
  --primary-color: #00578e;
  --primary-blue: oklch(40.46% 0.093 240.46); // #004e76
  --primary-blue-hover: oklch(72.34% 0.048 229.15); // #87acbe
  --primary-green: oklch(76.36% 0.205 136.57); // #6ece36
  --primary-gray: oklch(88.94% 0.019 232.53); // #cfdde5

  --border-color:oklch(88.94% 0.019 232.53); // #cfdde5
  --disabled-text: oklch(81.58% 0.032 231.41);

  --warning-color: oklch(83.07% 0.099 80.71); // #e9c17b
  --success-color: oklch(76.36% 0.205 136.57); // #6ece36
  --error-color: oklch(59.18% 0.199 27.35); // #db3a34

  --title-color: #495057;

  --color-primary:#00578e;
  --color-accent: #18a8e5;
  --color-primary-light: #e1f1f8;
  --color-primary-super-light: rgba(var(--color-primary), 0.4);
  --color-black-high-emphasize: rgba(0, 0, 0, 0.87);
  --color-black-medium-emphasize: rgba(0, 0, 0, 0.6);
  --color-black-low-emphasize: rgba(0, 0, 0, 0.54);
  --color-black-ultra-low-emphasize: rgba(0, 0, 0, 0.25);
  --color-text-disabled: #0000003d;
  --color-text-label: #00000099;
  --color-border: rgb(0,0,0, 0.1);
  --color-success: #388E3C;
  --color-error: #e53935;
  --color-warn:  #ffa000 ;
  --color-white: #ffffff;
  //--color-warn: #f44336;

  --border-radius: 5px;
  --box-shadow:  0px 2px 1px -1px rgba(0, 0, 0, 0.2),
  0px 1px 1px 0px rgba(0, 0, 0, 0.14),
  0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --box-shadow-hover:  0px 4px 1px -1px rgba(0, 0, 0, 0.2),
  0px 2px 1px 0px rgba(0, 0, 0, 0.14),
  0px 2px 3px 0px rgba(0, 0, 0, 0.12);
  --box-shadow-prominent:  0px 8px 12px 8px rgba(from var(--color-black-high-emphasize) r g b / 0.2),
  0px 6px 6px 0px rgba(0, 0, 0, 0.14),
  0px 6px 6px 0px rgba(0, 0, 0, 0.12);

  --font-size-big: 1.5rem; //if base=16px -> 24px
  --font-size-large: 1.25rem; //if base=16px -> 20px
  --font-size-base: 1rem; // default 16px
  --font-size-small: 0.875rem; //if base=16px -> 14px
  --font-size-tiny: 0.75rem; //if base=16px -> 12px
  --font-size-supertiny: 0.625rem; //if base=16px -> 10px
}

.inf-light-mode {
  background-color: #ffffff;
  @include root-definitions();
}


$infrest-palette-primary: (
  50: #e1f5fc,
  100: #b3e5f6,
  200: #83d3f1,
  300: #55c2eb,
  400: #34b5e8,
  500: #18a8e5,
  600: #129ad7,
  700: #0887c3,
  800: #0877b0,
  900: #00578e,
  A100: #5db4e6,
  A200: #0089cd,
  A400: #0070ac,
  A700: #00578e,
  contrast: (
    50: black,
    100: black,
    200: black,
    300: black,
    400: black,
    500: white,
    600: white,
    700: white,
    800: white,
    900: white,
    A100: black,
    A200: black,
    A400: white,
    A700: white
  )
);

//general main colors (parallel to mat-colors)
$infrest-primary-color: #00578e;
$infrest-accent-color: #18a8e5;
$infrest-warn-color: #f44336;

//general additional colors
$infrest-background-color-light: #fafafa;
$infrest-background-color-dark: #e8e8e8;

$infrest-primary-variant-700: #0887c3;
$infrest-black-high-emphasize: rgba(0, 0, 0, 0.87);
$infrest-black-medium-emphasize: rgba(0, 0, 0, 0.6);
$infrest-black-low-emphasize: rgba(0, 0, 0, 0.54);
$infrest-light-blue-e1: #e1f1f8;

//component + semantic colors
$infrest-error-color: #e53935;
$infrest-error-background-color: #fce7e7;

$infrest-success-color: #388e3c;
$infrest-success-background-color: #c8e6c9;

$infrest-success-color-base: #4caf50; //used for table icons
$infrest-error-color-dark: #c62828; //used for table icons

$infrest-warning-border-color: #ffc107;
$infrest-warning-color-dark: #ffa000;
$infrest-warning-background-color: #fffaf3;
$infrest-warning-text-color: #000;

$infrest-highlight-border-color: #83d3f1;
$infrest-highlight-background-color: #f1fbff;
$infrest-warning-text-color: #000;

$infrest-standard-border-color: #e5e5e5;
$infrest-disabled-text-color: #0000003d;
