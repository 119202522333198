.formly-field-float-25 {
  display: block;
  float: left;
  width: 24%;
  min-width: 100px;
  margin-left: 0.5%;
  margin-right: 0.5%;
}

.formly-field-float-33 {
  display: block;
  float: left;
  width: 32%;
  min-width: 140px;
  margin-left: 0.5%;
  margin-right: 0.5%;
}

.formly-field-float-50 {
  display: block;
  float: left;
  width: 49%;
  min-width: 200px;
  margin-left: 0.5%;
  margin-right: 0.5%;
}

.formly-field-float-full {
  display: block;
  float: left;
  width: 99%;
  min-width: 400px;
  margin-left: 0.5%;
  margin-right: 0.5%;
}

.formly-field-float-clear {
  clear: left;
}

.display-flex {
  display: flex;
  gap: 20px;
}

.v-centered-fields {
  // TODO check if this can be added to .display-flex
  align-items: center;
}

.flex-1 {
  flex: 1;
}

.flex-2 {
  flex: 2;
}

.flex-3 {
  flex: 3;
}

.flex-4 {
  flex: 4;
}

.flex-5 {
  flex: 5;
}

.flex-6 {
  flex: 6;
}

[class*='flex-']:first-child {
  padding-left: 0;
}

[class*='flex-']:last-child {
  padding-right: 0;
}

.link {
  padding: 0 !important;
}

.display-6col-grid {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: 20px;
}

.colspan-2 {
  grid-column: span 2;
}

.colspan-3 {
  grid-column: span 3;
}
.colspan-4 {
  grid-column: span 4;
}
.colspan-5 {
  grid-column: span 5;
}
.colspan-6 {
  grid-column: span 6;
}
