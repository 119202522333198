.mat-mdc-text-field-wrapper:has(.mdc-radio) {
  background-color: unset !important;

  .mdc-line-ripple::before, .mdc-line-ripple::after  {
    border-bottom: 0 solid transparent !important;
  }

  >.mat-mdc-form-field-focus-overlay {
    opacity: 0 !important;
  }
}
