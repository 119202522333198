@use '../base' as inf;

.file-link:link,
.file-link:visited {
  color: inf.$infrest-primary-variant-700;
  text-decoration: none;
}

.file-link:link:active,
.file-link:visited:active {
  color: inf.$infrest-primary-variant-700;
  text-decoration: none;
}
