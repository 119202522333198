.ql-toolbar {
  border-radius: 4px 4px 0 0 !important;
}

.editor {
  width: 100% !important;
}

.ql-editor {
  border-bottom: #8e8e8e solid 1px !important;
}

.ql-editor:hover {
  border-bottom: black solid 1px !important;
}

.ql-container.ql-snow {
  border: none !important;
  border-left: 1px solid #ccc !important;
  border-right: 1px solid #ccc !important;
}

.ql-container:hover {
  background-color: #eeeded !important;
}

.ql-editor.ql-blank::before {
  font-style: normal !important;
}

.ql-tooltip {
  left: 0 !important;
  top: 25px !important;
}