.mat-mdc-form-field-type-checkbox {
  padding: 0;

  .mat-mdc-form-field-subscript-wrapper {
    //margin-bottom: -1.25em;
    height: 0;
  }

  .mat-mdc-text-field-wrapper:not(.mdc-text-field--disabled) {
    padding: 0;
    background-color: transparent;

    .mat-mdc-form-field-focus-overlay {
      display: none;
    }

    .mdc-line-ripple {
      display: none;
    }

    .mat-mdc-form-field-infix {
      padding: 0 !important;
      min-height: unset;
    }
  }

  //disabled checkbox
  .mat-mdc-text-field-wrapper.mdc-text-field--disabled {
    padding: 0;

    .mdc-line-ripple {
      display: none;
    }

    .mat-mdc-form-field-infix {
      padding: 0 !important;
      min-height: unset;
    }
  }
}
