.mdc-list  {
  font-size: 14px;
  padding: 0;
}

span.mat-mdc-list-item-unscoped-content.mdc-list-item__primary-text {
  font-size: 14px;
}


.mat-mdc-menu-content.mdc-list,
.mat-mdc-menu-panel.mat-mdc-menu-panel {
  overflow: hidden;
  padding: 0;
}
