@use '@angular/material' as mat;
@use "sass:map";
@use '../base' as inf;

@include mat.core();
@include mat.all-component-typographies();
@include mat.all-component-densities(-1);

$inf-lap-frontend-primary: mat.define-palette(inf.$infrest-palette-primary, A700, A100, A200, A400);
$inf-lap-frontend-accent: mat.define-palette(inf.$infrest-palette-primary, 500, A100, A200, 500);
$inf-lap-frontend-warn: mat.define-palette(mat.$red-palette);
$inf-typography: mat.define-typography-config(
  $font-family: 'Roboto',
);

$inf-custom-button: mat.define-typography-level(
  $font-family: 'Roboto',
  $font-weight: 500,
  $font-size: 14px,
  $line-height: 1,
  $letter-spacing: 'normal'
);

$inf-custom-input: mat.define-typography-level(
  $font-family: 'Roboto',
  $font-weight: 400,
  $font-size: 14px,
  $line-height: 1,
  $letter-spacing: 'normal'
);

/* Merge custom configs into existing config */
$inf-typography-config: map.merge(
    mat.define-typography-config(
      /* 'button'-property will work out of the box */
      $button: $inf-custom-button
    ),
    (

      /* 'input'-property will have to be assigned under '@mixin typography' further below */
      'input': $inf-custom-input
    )
);

/* Apply custom config */
@include mat.all-component-typographies($inf-typography-config);

/* Let's assign the custom property 'input' that we defined above */
@mixin typography($theme) {
  $custom-typography-config: mat.get-typography-config($theme);

  .mat-mdc-form-field {
    @include mat.typography-level($custom-typography-config, 'input')
  }
}

$inf-theme-light: mat.define-light-theme(
    (
      color: (
        primary: $inf-lap-frontend-primary,
        accent: $inf-lap-frontend-accent,
        warn: $inf-lap-frontend-warn
      ),
      density: -2,
      typography: $inf-typography-config,
    )
);

@include mat.all-component-themes($inf-theme-light);

/* Apply custom app-theme */
@include typography($inf-theme-light);

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif;  box-sizing: border-box;}

.mdc-list {
  font-size: 14px;
}
