@use 'colors' as inf;

.justify-content-between {
  justify-content: space-between;
}

.justify-content-center {
  justify-content: center;
}

.text-right {
  text-align: right;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-start {
  text-align: start;
}

.text-end {
  text-align: end;
}

.padding-1 {
  padding: 0.5em !important;
}

.margin-left-1 {
  margin-left: 0.5em !important;
}

.margin-top-1 {
  margin-top: 0.5em !important;
}

.margin-top-2 {
  margin-top: 1em !important;
}

.margin-top-3 {
  margin-top: 1.5em !important;
}

.margin-top-4 {
  margin-top: 2em !important;
}

.padding-top-2 {
  padding-top: 2em !important;
}

.padding-bottom-2 {
  padding-bottom: 2em !important;
}

.padding-top-3 {
  padding-top: 3em !important;
}

.layout-main {
  justify-content: center;
  align-content: center;
  padding: 0;
  margin: 0;
  background: inf.$infrest-background-color-light;
  background-size: cover;
}
