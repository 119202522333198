@use '../base' as inf;

.note {
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  margin-top: 32px;
  padding: 16px;

  &.warning {
    background-color: inf.$infrest-warning-background-color;
    border-color: inf.$infrest-warning-border-color;
    color: inf.$infrest-warning-text-color;
  }

  &.important {
    background-color: #F1FBFF;
    border-color: #83D3F1;
    color: inf.$infrest-warning-text-color;
  }

  &:last-child {
    margin-bottom: 32px;
  }
}

.ng-star-inserted {
  .note {
    margin-top: 22px;
  }
}
