@use '../base' as inf;

textarea.mat-mdc-input-element.cdk-textarea-autosize {
  //line-height: 1.7em;
  //min-height: 70px;
}

textaread.mat-mdc-input-element::placeholder{
  color: red;
}

.mat-h1,
.mat-h2 {
  font-family: 'Roboto' !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: inf.$font-size-large !important;
  line-height: 30px !important;
  letter-spacing: 0.15px !important;
}

.mat-h1 {
  color: inf.$infrest-black-high-emphasize !important;
}

.mat-h2 {
  color: inf.$infrest-black-medium-emphasize !important;
}

.mat-mdc-form-field {
  width: 100%;
  //font-size: 12px !important;
  //font-weight: 400 !important;
  //line-height: 1.125 !important;
  //font-family: Roboto,Helvetica Neue,sans-serif !important;
  //letter-spacing: normal !important;
  //background-color: red;
}

.mat-mdc-text-field-wrapper .mat-mdc-form-field-infix {
  padding-top: 22px !important;
  padding-bottom: 6px !important;
}

.address-search-form-field > .mat-mdc-text-field-wrapper .mat-mdc-form-field-infix {
  padding-top: 12px !important;
  padding-bottom: 12px !important;
}

.mat-mdc-text-field-wrapper .mat-mdc-floating-label {
  //.mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined) .mat-mdc-floating-label {
  display: block !important;
  font-size: 14px !important;
}

.mat-mdc-option .mdc-list-item__primary-text {
  font-size: 14px !important;
}
