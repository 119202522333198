@use '../base' as inf;


.mat-mdc-snack-bar-container {
  margin-left: auto !important; // center align from left
  margin-right: auto !important; // center align from right
  margin-bottom: 1em !important;
  padding: 10px !important; // spacing between the text and boundary
  // background-color: inf.$infrest-error-background-color !important;
  // color: #c62828 !important;
  align-items: center !important;
  display: flex !important;

  .mat-mdc-button-wrapper {
    color: inf.$infrest-error-color !important; // action text color
  }

  .mat-mdc-snack-bar-action {
    flex-shrink: 0;
    margin: -8px 0 -8px 8px;
  }

  &.success {
    --mdc-snackbar-container-color: #{inf.$infrest-success-background-color};
    //--mat-mdc-snack-bar-button-color: #fff;
    --mdc-snackbar-supporting-text-color: #{inf.$infrest-success-color};
  }

  &.error {
    --mdc-snackbar-container-color: #{inf.$infrest-error-background-color}; 
    //--mat-mdc-snack-bar-button-color: #fff;
    --mdc-snackbar-supporting-text-color: #{inf.$infrest-warn-color};
  }
}
