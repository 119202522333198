@use 'base';
@use 'material';
@use 'components';

@import '~quill/dist/quill.snow.css';

html, body {
  height: 100%;
}

body {
  margin: 0;
  //font-family: Roboto, "Helvetica Neue", sans-serif;
  box-sizing: border-box;
}
 *{
   box-sizing: border-box;
 }


